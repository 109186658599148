<template>
  <div id="ground-area">
    <GridLayout
      id="layout"
      ref="layout"
      :layout="layout"
      :is-resizable="false"
      :is-draggable="false"
      :col-num="colNum"
      :row-height="50"
      :margin="[5, 5]"
      preserve-aspect-ratio
    >
      <GridItem
        v-for="item in layout"
        :minH="2"
        :minW="2"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        :class="['container']"
      >
        <div class="container-box">
          <div
            :class="`element-list ${
              item.props && item.props.direction === 'vertical'
                ? 'element-list-for-vertical'
                : ''
            }`"
            v-if="item.elements"
          >
            <div
              v-for="(element, i) of item.elements"
              :key="i"
              :style="
                getCSSAccordingToContainerDirection(
                  item.props && item.props.direction
                    ? item.props.direction
                    : '',
                  element
                )
              "
              style="padding: 5px"
            >
              <div
                :class="['d-flex flex-column align-center element']"
                :style="
                  selectedType == 'real'
                    ? 'background-color: transparent;border:unset;'
                    : ''
                "
              >
                <div
                  id="structural"
                  class="parentDiv"
                  v-if="selectedType == 'structural'"
                >
                  {{ element.name }}
                </div>
                <div
                  id="realData"
                  class="parentDiv"
                  v-if="selectedType == 'real'"
                >
                  <template
                    v-if="
                      bindStyle[element.elemLinkTo] &&
                      bindStyle[element.elemLinkTo].visibility &&
                      bindStyle[element.elemLinkTo].componentFn
                    "
                  >
                    <div
                      class="elementrender"
                      style="width: 100%; height: 100%"
                    >
                      <component
                        :key="element.elemLinkTo + 'elementrender'"
                        :is="bindStyle[element.elemLinkTo].componentFn"
                        v-bind="localVersionOfElementsData[element.elemLinkTo]"
                      />
                    </div>
                  </template>
                  <template
                    v-else-if="
                      !bindStyle[element.elemLinkTo] ||
                      !bindStyle[element.elemLinkTo].visibility ||
                      !bindStyle[element.elemLinkTo].componentFn
                    "
                  >
                    <div class="noPreview">
                      <h4>
                        {{
                          (bindStyle[element.elemLinkTo] &&
                            bindStyle[element.elemLinkTo].message) ||
                          'No Preview ' + element.name 
                        }}
                      </h4>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GridItem>
    </GridLayout>
  </div>
</template>



<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import { createNamespacedHelpers as cnh } from 'vuex'
const { mapState: mapReportBuilderState } = cnh('ReportBuilder')
const { mapState, mapGetters } = cnh('PreviewStore')

export default {
  components: {
    GridLayout,
    GridItem
  },
  computed: {
    ...mapReportBuilderState(['layout', 'localVersionOfElementsData']),
    ...mapState(['structuralPreview']),
    ...mapGetters(['selectedType', 'selectedDevice', 'selectedDataType'])
  },
  data() {
    return {
      colNum: 12,
      bindStyle: {}
    }
  },
  methods: {
    getCSSAccordingToContainerDirection(direction, element) {
      if (element) {
        // console.log('direction, element :>> ', direction, element);
        return direction === 'vertical'
          ? {
              'min-height': `calc(100% / ${this.colNum} * ${element.size}`,
              height: `calc(100% / ${this.colNum} * ${element.size}`
            }
          : {
              'min-width': `calc(100% / ${this.colNum} * ${element.size}`,
              width: `calc(100% / ${this.colNum} * ${element.size}`
            }
        // console.log('t :>> ', t);
        // return t
      }
    },
    isContainerDirectionIsVertical(container = {}) {
      return (
        container.props &&
        container.props.direction &&
        container.props.direction === 'vertical'
      )
    },
    async getBackground() {
      console.log('************************')
      for (let item of this.layout) {
        for (let elem of item.elements || []) {
          if (!this.bindStyle[elem.elemLinkTo]) {
            this.bindStyle[elem.elemLinkTo] = {}
          }
          const elemConf = this.localVersionOfElementsData[elem.elemLinkTo]
          this.bindStyle[elem.elemLinkTo]['visibility'] = false
          if (elemConf && elemConf.subType) {
            try {
              if (elemConf.configuration) {
                let comp = await this.previewComponent(elemConf)
                if (comp) {
                  this.bindStyle[elem.elemLinkTo].componentFn = comp
                  this.bindStyle[elem.elemLinkTo]['visibility'] = true
                }
              } else {
                this.bindStyle[elem.elemLinkTo]['message'] =
                  'No Configuration Provided'
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      }
      this.$forceUpdate()
      console.log(this.bindStyle, '0000000000000000000')
    },
    async previewComponent(selected) {
      try {
        let compPath = `../../elements-components/${selected.type.toLowerCase()}/${
          selected.subType
        }.vue`
        console.log('@@@@', compPath)
        if (selected.type == 'graph') {
          return () =>
            import(`../../elements-components/Graphs/ChartPreview.vue`)
        }
        await import(
          `../../elements-components/${selected.type.toLowerCase()}/${
            selected.subType
          }.vue`
        )
        return () =>
          import(
            `../../elements-components/${selected.type.toLowerCase()}/${
              selected.subType
            }.vue`
          )
      } catch (error) {
        console.error(error)
        return false
      }
    }
  },
  watch: {
    layout: {
      handler() {
        console.log(this)
        // this.bindStyle = {}
        this.$forceUpdate()
      },
      deep: true
    },
    selectedType() {
      if (this.selectedType == 'real') {
        this.getBackground()
      }
    }
  }
}
</script>

<style scoped lang="scss">
$black-border: 1px solid grey;

#ground-area {
  max-width: 100%;
  max-height: 86vh; // previously it was 560px
  min-width: 800px;
  min-height: 86vh; // previously it was 560px
  overflow-x: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.parentDiv {
  display: flex;
  // border: 1px solid blue;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container-box {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: calc(100% - 20px);
  position: absolute;
  left: 0;
  top: 0;
}

.element-list {
  display: flex;
  margin: 0.25rem;
  height: 100%;
  overflow: auto;
}
.element-list-for-vertical {
  flex-direction: column;
}
.element {
  border: $black-border;
  height: 100%;
  background-color: $cornflower;
}
</style>
