var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ground-area"}},[_c('GridLayout',{ref:"layout",attrs:{"id":"layout","layout":_vm.layout,"is-resizable":false,"is-draggable":false,"col-num":_vm.colNum,"row-height":50,"margin":[5, 5],"preserve-aspect-ratio":""}},_vm._l((_vm.layout),function(item){return _c('GridItem',{key:item.i,class:['container'],attrs:{"minH":2,"minW":2,"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":item.i}},[_c('div',{staticClass:"container-box"},[(item.elements)?_c('div',{class:("element-list " + (item.props && item.props.direction === 'vertical'
              ? 'element-list-for-vertical'
              : ''))},_vm._l((item.elements),function(element,i){return _c('div',{key:i,staticStyle:{"padding":"5px"},style:(_vm.getCSSAccordingToContainerDirection(
                item.props && item.props.direction
                  ? item.props.direction
                  : '',
                element
              ))},[_c('div',{class:['d-flex flex-column align-center element'],style:(_vm.selectedType == 'real'
                  ? 'background-color: transparent;border:unset;'
                  : '')},[(_vm.selectedType == 'structural')?_c('div',{staticClass:"parentDiv",attrs:{"id":"structural"}},[_vm._v(" "+_vm._s(element.name)+" ")]):_vm._e(),(_vm.selectedType == 'real')?_c('div',{staticClass:"parentDiv",attrs:{"id":"realData"}},[(
                    _vm.bindStyle[element.elemLinkTo] &&
                    _vm.bindStyle[element.elemLinkTo].visibility &&
                    _vm.bindStyle[element.elemLinkTo].componentFn
                  )?[_c('div',{staticClass:"elementrender",staticStyle:{"width":"100%","height":"100%"}},[_c(_vm.bindStyle[element.elemLinkTo].componentFn,_vm._b({key:element.elemLinkTo + 'elementrender',tag:"component"},'component',_vm.localVersionOfElementsData[element.elemLinkTo],false))],1)]:(
                    !_vm.bindStyle[element.elemLinkTo] ||
                    !_vm.bindStyle[element.elemLinkTo].visibility ||
                    !_vm.bindStyle[element.elemLinkTo].componentFn
                  )?[_c('div',{staticClass:"noPreview"},[_c('h4',[_vm._v(" "+_vm._s((_vm.bindStyle[element.elemLinkTo] && _vm.bindStyle[element.elemLinkTo].message) || 'No Preview ' + element.name)+" ")])])]:_vm._e()],2):_vm._e()])])}),0):_vm._e()])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }